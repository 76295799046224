import cookies from "js-cookie";

const ACCESS_TOKEN_NAME = "AccessToken";

export const auth = {
  setAccessToken: (token, options) => {
    cookies.set(ACCESS_TOKEN_NAME, token, options);
  },

  getAccessToken: () => {
    return cookies.get(ACCESS_TOKEN_NAME);
  },

  isAuthenticated: () => {
    return !!auth.getAccessToken();
  },
};
