const users = [
  {
    username: "pdaxclient",
    password: "bDXYJtvjdds99hZZLyNq",
  },
  {
    username: "CaaS",
    password: "pdax123456",
  },
];

export default users;
